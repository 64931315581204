import React from 'react'

function Phrase() {
   return (
      <div className='w-full bg-black mt-40'>
         <div className='max-w-[1280px] h-[25rem] mx-auto font-body text-white text-center px-4 flex justify-center items-center'>
            <div>
               <h2 className='text-7xl max-md:text-5xl'>We provide the fastest, easies and most effective way</h2>
            </div>

         </div>
      </div>
   )
}

export default Phrase