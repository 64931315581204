export const data = [

   // Communication and IT System New Gen Solution Service 0-7

   {
      title: 'Network and Wireless Solutions',
      description:
         'Empowering connectivity with innovative network and wireless solutions for all.',
   },
   {
      title: 'Data Center & Server Room Solutions',
      description:
         'Optimize operations with cutting-edge data center and server room solutions.',
   },
   {
      title: 'CCTV System & Cabling Solutions',
      description:
         'Enhance security with comprehensive CCTV system and cabling solutions.',
   },
   {
      title: 'Server Storage and Virtualization Solutions',
      description:
         'Maximize efficiency with advanced server, storage, and virtualization solutions.',
   },
   {
      title: 'IP Phone & Video Conference Solutions',
      description:
         'Seamless communication through tailored IP phone and video conference solutions.',
   },
   {
      title: 'Public Address',
      description:
         'Amplify messages with reliable public address systems for diverse environments.',
   },
   {
      title: 'IT Security',
      description:
         'Comprehensive IT security services covering SOC, NOC, CSOC, JOG, JCOC, security auditing.',
   },
   {
      title: 'Property Management System',
      description:
         'Elevate property management with integrated solutions spanning smart properties.',
   },


   // Mobile App and Software Blockchain Solution 8-11

   {
      title: 'Software Development Service',
      description:
         'Tailored software development services delivering scalable, efficient, and innovative solutions to meet diverse business needs and exceed expectations.',
   },
   {
      title: 'Fintech and Blockchain Service',
      description:
         'Innovative fintech and blockchain services revolutionizing finance and technology.',
   },
   {
      title: 'ERP Business',
      description:
         'Streamline operations with comprehensive ERP solutions for enhanced business efficiency.',
   },
   {
      title: 'IPTV Solution',
      description:
         'Revolutionize entertainment and communication with cutting-edge IPTV solutions, offering seamless streaming and interactive features for diverse audiences.',
   },


]

