import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { Link, NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

function Footer() {
   return (
      <div id='Contact' className='w-full bg-black'>
         <div className='max-w-[1280px] mx-auto px-4 font-body mb-5'>
            <div className='flex justify-between pt-10 max-md:flex-col'>

               <div className='flex min-w-[50%]'>
                  <div className='min-w-[30%] max-md:min-w-[50%]'>
                     <h2 className='text-[#FF5A26] font-bold text-xl'>Company</h2>
                     <ul className='text-slate-500 pt-2'>
                        <NavHashLink smooth exact to="#About"
                        ><li className='hover:hover:text-white ease-out duration-500 cursor-pointer'>About</li></NavHashLink>
                        <NavHashLink smooth exact to="#Service"
                        ><li className='hover:hover:text-white ease-out duration-500 cursor-pointer'>Service</li></NavHashLink>
                        <NavHashLink smooth exact to="#Partners"
                        ><li className='hover:hover:text-white ease-out duration-500 cursor-pointer'>Partners</li></NavHashLink>
                     </ul>
                  </div>

                  <div className='min-w-[70%] max-md:min-w-[50%]'>
                     <h2 className='text-[#FF5A26] font-bold text-xl'>Contact</h2>
                     <Link to='javascript:void(0)'
                        onClick={() => window.location = 'mailto:info@siamdapp.com'}>
                        <p className='text-slate-500 pt-2 hover:hover:text-white ease-out duration-500 cursor-pointer'>info@siamdapp.com</p>
                     </Link>
                     <a href="tel: +66 614-644-519" ><p className='text-slate-500 hover:hover:text-white ease-out duration-500 cursor-pointer'>+66 094-798-9891</p></a>
                  </div>
               </div>


               <div className='min-w-[50%] max-md:mt-5'>
                  <h2 className='text-[#FF5A26] font-bold text-xl'>Contact</h2>
                  <p className='text-slate-500 pt-2'>398 Chiang Mai - Lampang Road, Faham Sub District Muang District, Chiang Mai, Thailand 50000</p>
               </div>
            </div>

            <div className='flex justify-between items-center text-slate-500 text-sm border-t border-slate-800 mt-20 pt-5 max-md:flex-col'>
               <div>
                  <p>Copyright © 2024 by SiamDapp Co. Ltd.</p>
               </div>

               <div className='flex items-center'>
                  <NavLink exact to='./PrivacyPolicy'>
                  <a className='pl-4 text-slate-500 hover:hover:text-white ease-out duration-500 cursor-pointer' href="/">Privacy Policy</a>
                  </NavLink>
                  


                  <div className='flex items-center pl-10 max-md:pl-4'>
                     <p>Follow Us</p>
                     <a href="https://www.facebook.com/profile.php?id=100054227618451" target="_blank" rel="noreferrer" className='pl-3 cursor-pointer'><BsFacebook size={26} color='#334155'
                        onMouseOver={({ target }) => target.style.color = "#64748b"}
                        onMouseOut={({ target }) => target.style.color = "#334155"}
                     /></a>
                  </div>


               </div>
            </div>
         </div>
      </div>
   )
}

export default Footer