import React from 'react'
import BlackCard from './CommunicationCard/BlackCard'
import BeigeCard from './CommunicationCard/BeigeCard'
import { data } from './Data'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'


function CommunicationAndITSystem() {
   const [width, setWidth] = useState(0);
   const carousel = useRef();

   useEffect(() => {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);

   }, []);

   return (
      <div>
         <motion.div ref={carousel} whileTap={{ cursor: 'grabbing' }} className='carousel cursor-grab overflow-hidden w-full px-4 max-sm:w-640px'>
            <div className='max-w-[800px] mx-auto text-center mt-28 mb-20 max-md:mt-20 max-md:mb-10'>
               <h2 className='font-body text-5xl font-bold max-md:text-4xl'>Communication and IT System New Gen Solution Service</h2>
            </div>
            <motion.div
               drag='x'
               dragConstraints={{ right: 0, left: -width }}
               className='inner-carousel flex'>
               <BlackCard
                  title={data[0].title}
                  description={data[0].description}
               />

               <BeigeCard
                  title={data[1].title}
                  description={data[1].description}
               />

               <BlackCard
                  title={data[2].title}
                  description={data[2].description}
               />

               <BeigeCard
                  title={data[3].title}
                  description={data[3].description}
               />

               <BlackCard
                  title={data[4].title}
                  description={data[4].description}
               />

               <BeigeCard
                  title={data[5].title}
                  description={data[5].description}
               />

               <BlackCard
                  title={data[6].title}
                  description={data[6].description}
               />

               <BeigeCard
                  title={data[7].title}
                  description={data[7].description}
               />
            </motion.div>
         </motion.div>
      </div>
   )
}

export default CommunicationAndITSystem