import React from 'react'
import LogoVMOThailand from '../img/itOutsourcing/logoVmo.svg'
import LogoValix from '../img/itOutsourcing/logoValix.svg'
import IconCertificated from '../img/itOutsourcing/iconCerificated.svg'

function ITOutsoursing() {
   return (
      <div className='w-full'>
         <div className='max-w-[1280px] mx-auto font-body px-4'>
            <div className='mx-auto text-center mt-28 mb-20 max-md:mt-20 max-md:mb-10'>
               <h3 className='text-5xl font-bold max-md:text-4xl'>IT Outsourcing Service</h3>
            </div>

            <div className='flex justify-between max-lg:flex-col max-lg:items-center'>
               <div className='w-[49.5%] max-lg:w-full'>
                  <div className='min-h-[10rem] flex justify-center border rounded-xl'>
                     <img src={LogoVMOThailand} alt="vmo thailand" />
                  </div>
                  <div className='flex justify-center items-center pt-4'>
                     <img src={IconCertificated} alt="icon certificated" />
                     <p className='pl-2'>One-stop destination for all technology solutions</p>
                  </div>
               </div>

               <div className='w-[49.5%] max-lg:mt-10 max-lg:w-full'>
                  <div className='min-h-[10rem] flex justify-center border rounded-xl'>
                     <img src={LogoValix} alt="vmo thailand" />
                  </div>
                  <div className='flex justify-center items-center pt-4'>
                     <img src={IconCertificated} alt="icon certificated" />
                     <p className='pl-2'>One-stop destination for all technology solutions</p>
                  </div>
               </div>
            </div>

         </div>
      </div>
   )
}

export default ITOutsoursing