import React from 'react'
import logoTrueMove from '../img/ourPartners/Implementation/logoTrueMove.jpg'
import logoKrungthaiBank from '../img/ourPartners/Implementation/logoKrungthaiBank.jpg'
import logoOmsinBank from '../img/ourPartners/Implementation/logoOmsinBank.jpg'
import logoUIH from '../img/ourPartners/Implementation/logoUIH.jpg'
import logoCaat from '../img/ourPartners/Implementation/logoCaat.jpg'
import logoPEA from '../img/ourPartners/Implementation/logoPEA.jpg'
import logoUtapao from '../img/ourPartners/Implementation/logoUtapao.jpg'
import logoTAF from '../img/ourPartners/Implementation/logoTAF.jpg'
import logoRAF from '../img/ourPartners/Implementation/logoRAF.jpg'
import logoMEA from '../img/ourPartners/Implementation/logoMEA.jpg'
import logoBWFC from '../img/ourPartners/Implementation/logoBWFC.jpg'
import logoRMUT from '../img/ourPartners/Implementation/logoRMUT.jpg'
import logoDepartmentStore from '../img/ourPartners/SoftwareMA/logoDepartmentStore.jpg'
import logoSamartInnovation from '../img/ourPartners/SoftwareMA/logoSamartInnovation.jpg'
import logoThaiSmile from '../img/ourPartners/Consultant/logoThaiSmile.jpg'
import logoSahapat from '../img/ourPartners/Consultant/logoSahapat.jpg'
import logoAlt from '../img/ourPartners/Consultant/logoAlt.jpg'
import logoKrungthepThanakom from '../img/ourPartners/PPP MOU and Concession/logoKrungthepThanakom.jpg'
import logoKoratMuseum from '../img/ourPartners/PPP MOU and Concession/logoKoratMuseum.jpg'
import logoNakhornRatchasima from '../img/ourPartners/PPP MOU and Concession/logoNakhornRatchasima.jpg'
import logoChestInstitute from '../img/ourPartners/PPP MOU and Concession/logoChestInstitute.jpg'
import logoChaiyaphumProvince from '../img/ourPartners/PPP MOU and Concession/logoChaiyaphumProvince.jpg'
import logoNectec from '../img/ourPartners/IoTs Sensors and City Platform/logoNectec.jpg'
import logoMurata from '../img/ourPartners/IoTs Sensors and City Platform/logoMurata.jpg'
import logoDFRC from '../img/ourPartners/IoTs Sensors and City Platform/logoDFRC.jpg'
import logoTHL from '../img/ourPartners/Blockchain/logoTHL.jpg'
import logoBirdExpress from '../img/ourPartners/Blockchain/logoBirdExpress.jpg'
import logoVelaVerse from '../img/ourPartners/Blockchain/logoVelaVerse.jpg'



export default function OurPartners() {
   return (
      <div id='Partners' className='w-full'>
         <div className='max-w-[1280px] mx-auto mt-28 mb-60 font-body text-center px-4'>
            <div>
               <h2 className='text-5xl font-bold max-md:text-4xl'>Our Partners</h2>
            </div>

            {/* Implementation */}
            <div>

               <div className='grid grid-cols-4 gap-2 mt-20 max-xl:grid-cols-3 max-md:grid-cols-2'>

                  {/* 1 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoTrueMove} alt="logo True Move" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoKrungthaiBank} alt="logo Krungthai Bank" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoOmsinBank} alt="logo Omsin Bank" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoUIH} alt="logo UIH" />
                  </div>

                  {/* 2 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoCaat} alt="logo Caat" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoPEA} alt="logo PEA" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoUtapao} alt="logo Utapao" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoTAF} alt="logo TAF" />
                  </div>

                  {/* 3 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoRAF} alt="logo RAF" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoMEA} alt="logo MEA" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoBWFC} alt="logo BWFC" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoRMUT} alt="logo RMUT" />
                  </div>

                  {/* 4 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoThaiSmile} alt="logo Thai Smile" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoSahapat} alt="logo Sahapat" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoAlt} alt="logo Alt" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoKrungthepThanakom} alt="logo Krungthep Thanakom" />
                  </div>

                  {/* 5 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoKoratMuseum} alt="logo Korat Museum" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoNakhornRatchasima} alt="logo Nakhorn Ratchasima" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoChestInstitute} alt="logo Chest Institute" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoChaiyaphumProvince} alt="logo Chaiyaphum Province" />
                  </div>

                  {/* 6 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoDepartmentStore} alt="logo Department Store" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoSamartInnovation} alt="logo Samart Innovation" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoNectec} alt="logo Nectec" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoMurata} alt="logo Murata" />
                  </div>

                  {/* 7 line */}
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoDFRC} alt="logo DFRC" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoTHL} alt="logo THL" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoBirdExpress} alt="logo Bird Express" />
                  </div>
                  <div className='min-w-[19%] h-[7.5rem] border rounded-lg bg-white flex justify-center items-center'>
                     <img src={logoVelaVerse} alt="logo Vela Verse" />
                  </div>

               </div>
            </div>

         </div>
      </div>
   )
}
