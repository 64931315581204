import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Hero from '../components/Hero';
import Service from '../components/CompanyService'
import CommunicationAndITSystem from '../components/CommunicationAndITSystem';
import MobileApp from '../components/MobileApp';
import ITOutsoursing from '../components/ITOutsoursing';
import Phrase from '../components/Phrase';
import OurPartners from '../components/OurPartners';

function Home() {
   return (
      <div>
         <ScrollToTop />
         <Hero />
         <Service />
         <CommunicationAndITSystem />
         <MobileApp />
         <ITOutsoursing />
         <Phrase />
         <OurPartners />
      </div>
   );
}

export default Home;
