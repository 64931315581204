import React from 'react'
import iconCommunicationIT from '../img/serviceIcons/iconCommunicationIT.svg'
import iconMobileApp from '../img/serviceIcons/iconMobileApp.svg'
import iconITOutsourcing from '../img/serviceIcons/iconITOutsourcing.svg'



function CompanyService() {
   return (
      <div id='Service' className='w-full bg-[#F6F4EF] rounded-b-lg'>
         <div className='max-w-[1280px] w-full mx-auto px-4 font-body font-medium text-xl grid grid-cols-3 gap-5 pt-20 pb-32 max-md:grid-cols-1'>
            <div className='bg-white px-6 py-6 rounded-lg'>
               <img src={iconCommunicationIT} alt="icon communication IT" />
               <h3 className='pt-4'>Communication and IT System New Gen Solution Service</h3>
            </div>
            <div className='bg-white px-6 py-6 rounded-lg'>
               <img src={iconMobileApp} alt="icon communication IT" />
               <h3 className='pt-4'>Mobile App and Software Blockchain Solution</h3>
            </div>
            <div className='bg-white px-6 py-6 rounded-lg'>
               <img src={iconITOutsourcing} alt="icon communication IT" />
               <h3 className='pt-4'>IT Outsourcing Service</h3>
            </div>
         </div>
      </div>
   )
}

export default CompanyService