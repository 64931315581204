import React from 'react'

function PrivacyPolicy() {
    return (
        <div className='w-full bg-black'>
            <div className='max-w-[1280px] mx-auto my-40 font-body text-justify'>
                <div>
                    <h1 className='text-5xl font-bold bg-gradient-to-r from-orange-400 to-red-700 inline-block text-transparent bg-clip-text max-md:text-4xl'>Privacy Policy</h1>
                </div>
                <div className='text-white'>
                    <p className='mt-10'>
                        SIAM DAPP Co. Ltd understands the value of non-disclosure of confidential data and personal data. Our company believes that such aspects of life should not be divulged without the client’s consent, and also can not be used for advertising and other purposes.
                    </p>
                    <p className='mt-5'>
                        Our clients without consent do not have to indicate personal information when visiting sites related to SIAM DAPP Co. Ltd. We collect only those data about users, which they voluntarily leave in the contacts section, through CRM or by any other method. We do not force the client to indicate his personal information, which he for some reason does not want to disclose.
                    </p>
                    <p className='mt-5'>
                        The personal data of the client has access to a specially appointed employee who works directly with him, no other specialist has access to the user’s confidential information. The safety of personal information is monitored by IT employees. The IT department constantly monitors the security of non-disclosure of information without access to it.
                    </p>
                    <p className='mt-5'>
                        This allows you to protect your personal data from unauthorized access to them by third parties. All the information that was provided to our resource is protected. It is stored by our employee, who works with the owner of this data. Before you start working with confidential information, each employee has signed a non-disclosure agreement (NDA) and is responsible for its safety. This serious responsibility is entrusted only to the most reliable of our employees.
                    </p>
                    <p className='mt-5'>
                        Advocate secrets are protected documents that are provided in paper form. The company uses the client’s personal data and other confidential information solely for the purpose of performing the services ordered by the client from the company.
                    </p>
                    <p className='mt-5'>
                        Our company does not use the confidential data of its clients for its own purposes. Eternity Law International does not transfer personal data of its users to third parties. It does not sell confidential information for its use in advertising purposes. Visitors to the company’s resource will never be bothered by marketers who have received their data through Eternity Law International.
                    </p>
                    <p className='mt-5'>
                        SIAM DAPP Co. Ltd has the ability to change the privacy statement without the user’s agreement. Such a policy does not imply the signing of a formal agreement between the company and its user, who came to its resource or provided its personal data.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy