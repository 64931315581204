import React from 'react'


export default function Button({ children }) {
   return (
      <>
         <button className='btnWhiteBorder'>{children}</button>
      </>

   )
}