import React from 'react'
import Button from './Button/Button';
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Hero() {
   return (
      <div id='About' className='w-full px-4 bg-black'>
         <div className='max-w-[1280px] w-full h-screen justify-center items-center mx-auto flex flex-col text-center font-body'>
            <div className=''>
               <h1 className='text-white text-7xl font-medium max-md:text-5xl'>Leading tech firm offers <span className='bg-gradient-to-r from-orange-400 to-red-700 inline-block text-transparent bg-clip-text max-md:text-5xl'>integrated solutions</span></h1>
               <p className='text-slate-500 text-lg pt-6 max-md:text-sm'>SiamDApp Co., Ltd. provides expert digital platform development services to drive your organization's success in the digital age. Our team creates cutting-edge, user-friendly solutions tailored to boost efficiency and growth. With expertise in cloud computing, AI, blockchain, and IoT, we deliver secure platforms for seamless expansion in the digital world.</p>

            </div>
            <Button>
               <Link to='javascript:void(0)'
                  onClick={() => window.location = 'mailto:info@siamdapp.com'}>
                  <div className='flex items-center'>
                     <p className='text-slate-500 pr-3 cursor-pointer'>Send offer</p>
                     <FaArrowRightLong color='#FF5A26' size={20} />
                  </div>
               </Link>
            </Button>
            
         </div>
      </div>
   )
}

export default Hero