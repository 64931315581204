import React from 'react'
import IconArrow from '../../img/iconArrow.svg'

function BlackCard({ title, description }) {
   return (
      <div className='w-full'>
         <div className='min-w-[320px] h-[485px] flex flex-col justify-between bg-black font-body px-7 py-10 rounded-lg'>
            <h3 className='text-white text-3xl font-medium'>{title}</h3>
            <div>
               <img src={IconArrow} alt="icon arrow" />
               <p className='text-slate-500 text-body pt-4'>{description}</p>
            </div>

         </div>
      </div>
   )
}

export default BlackCard