import React from 'react'
import IconArrow from '../../img/iconArrow.svg'

function BeigeCard({title, description}) {
   return (
      <div className='w-full px-4'>
         <div className='min-w-[320px] h-[485px] flex flex-col justify-between bg-[#EAE7E0] font-body px-7 py-10 rounded-lg'>
            <h3 className='text-black text-3xl font-bold'>{title}</h3>
            <div>
               <img src={IconArrow} alt="icon arrow" />
               <p className='text-slate-500 text-body pt-4'>{description}</p>
            </div>

         </div>
      </div>
   )
}

export default BeigeCard